import React, {useEffect, useState} from "react";
import { useTour } from '@reactour/tour';
import { Http2ServerResponse } from "http2";

export interface stepDict {
    selector: string;
    heading: string;
    description: string;
}

export function tourStepsManager(steps: stepDict[]) {

    const { setIsOpen, setSteps } = useTour()

    // Leaving a hack here to allow a second before the tour renders.
    // This should probably be better handled via dependencies on the API requests,
    // but the api client in portal isn't great yet
    useEffect(() => {
      const timeout = setTimeout(() => {
        setIsOpen(true);
        const formattedSteps = steps.map((step) => {
          return {
              'selector': step.selector,
              'content': () => (
                  <div>
                    <h2 style={{textAlign: "start", fontSize: "20px"}}>{step.heading}</h2>
                    <p style={{textAlign: "start", fontSize: "14px"}} >{step.description}</p>
                  </div>
                )
          }
        });
        setSteps!(formattedSteps);
      }, 1000)

    return () => clearTimeout(timeout);

    }, []);
}


import { NavBar } from "Components/NavBar/NavBar";
import React, { useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MetaContext } from "../Providers/MetaProvider";

import "./PageLayout.css";

import ConnectorsIcon from "../../assets/ConnectorsIcon.png";
import AuthCredentialsIcon from "../../assets/AuthCredentialsIcon.png";
import DashboardsIcon from "../../assets/DashboardsIcon.png";
import PlusSign from "../../assets/PlusSign.png";
import LogOut from "../../assets/LogoutIcon.png";
import PersonIcon from "../../assets/PersonIcon.svg";

import { logoutFromAPI } from "util/API";
import { ErrorContext } from "Components/Providers/Error";

export const PageLayout: React.FC<{
  children: JSX.Element;
  title?: string;
  CTAButton?: CTAButton;
  requireConnectors?: boolean;
}> = ({ title, children, CTAButton, requireConnectors = true }) => {
  let links = [
    { label: "Data Connectors", link: "/home", icon: ConnectorsIcon },
    { label: "Auth Credentials", link: "/accounts", icon: AuthCredentialsIcon },
    { label: "Dashboards", link: "/dashboards", icon: DashboardsIcon },
    { label: "Team", link: "/team", icon: PersonIcon },
  ];

  // Hides dashboard tab for non-legacy users.
  const metaContext = useContext(MetaContext);
  // @ts-ignore
  const userInfo = metaContext?.fullMeta?.user_info;
  if (!userInfo?.is_superset_user) {
    const index = links.findIndex((link) => link.label === "Dashboards");
    if (index !== -1) {
      links.splice(index, 1);
    }
  }

  const errorContext = useContext(ErrorContext);
  let navigate = useNavigate();
  let location = useLocation();
  let pathName = location.pathname;

  let components = children;

  const handleLogoutClick = () => {
    logoutFromAPI(errorContext).then((logout_url) => {
      window.location.href = logout_url;
    });
  };

  components = (
    <div>
      <NavBar />
      <div className="pageLayoutSection">
        <div className="leftPageSection">
          <div>
            <div className="leftMenuHeader">
              <h2>Admin Portal</h2>
            </div>
            <div className="leftMenuLinks">
              {links.map((link) => {
                return (
                  <Link
                    key={link.label}
                    to={link.link}
                    className="leftMenuLink"
                  >
                    <div
                      className={`leftMenuLinkContainer ${
                        pathName === link.link && "active"
                      }`}
                    >
                      <img src={link.icon} className="leftMenuLinkIcon" />
                      <p className="leftMenuLinkText">{link.label}</p>
                    </div>
                  </Link>
                );
              })}
            </div>
          </div>
          <button
            onClick={() => handleLogoutClick()}
            className="logOutContainer"
          >
            <div className="leftMenuLinkContainer">
              <img src={LogOut} className="leftMenuLinkIcon" />
              <p className="leftMenuLinkText">Log out</p>
            </div>
          </button>
        </div>
        <div className="rightPageSection">
          {title && (
            <div className="rightPageHeader">
              <h3 className="pageTitle">{title}</h3>
              {CTAButton && (
                <button
                  className="CTAButton"
                  onClick={() => navigate(CTAButton.link, { replace: true })}
                >
                  <img
                    src={PlusSign}
                    className="CTAButtonPlusIcon"
                    alt="plus sign"
                  />
                  {CTAButton.label}
                </button>
              )}
            </div>
          )}
          <div className="pageComponent">{components}</div>
        </div>
      </div>
    </div>
  );

  return components;
};

export type CTAButton = {
  link: string;
  label: string;
};

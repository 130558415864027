import { MetaContext } from "../../Providers/MetaProvider";
import React, { useContext, useState } from "react";
import CustomizedInputBase from "../../SearchBar";
import { getConnectorIcon } from "../../../util/ConnectorIcons";
import { ConnectorConfig } from "../../../types";
import { useTour } from '@reactour/tour'
import { tourStepsManager } from '../../ReactTour'

import GoogleAnalyticsLogo from '../../../assets/google-analytics-logo.png';
import FacebookLogo from '../../../assets/FacebookLogo.png'
import GoogleSheetsLogo from '../../../assets/GoogleSheetsLogo.png';
import LinkedinLogo from '../../../assets/LinkedIn_logo.png';
import TwitterLogo from '../../../assets/Twitter-logo.png';
import TikTokLogo from '../../../assets/TikTokLogo.png';
import AmplitudeLogo from '../../../assets/AmplitudeLogo.png'
import SnapchatLogo from '../../../assets/SnapchatLogo.svg';
import AmazonAdsLogo from '../../../assets/AmazonAdsLogo.png';
import GoogleLogo from '../../../assets/GoogleLogo.svg';
import RakutenLogo from '../../../assets/RakutenLogo.svg';
import CampaignMonitorLogo from '../../../assets/CampaignMonitorLogo.png';
import SendInBlueLogo from '../../../assets/SendInBlueLogo.png';
import QuantcastLogo from '../../../assets/QuantcastLogo.png';
import SemrushLogo from '../../../assets/SemrushLogo.png';

import Dialog from "@mui/material/Dialog";

import "./ConnectorTypeSelect.css";
import {FalseConnectorModal} from "../../FalseConnectorModal/FalseConnectorModal";
import {APIPost} from "../../../util/API";
import {ErrorContext} from "../../Providers/Error";


const falseConnectorNameToIcon: Record<string, string> = {
    'Twitter Ads': TwitterLogo,
    'LinkedIn Organic': LinkedinLogo,
    'Snapchat Ads': SnapchatLogo,
    'Amazon Ads': AmazonAdsLogo,
    'Google Search Console': GoogleLogo,
    'Rakuten': RakutenLogo,
    'Campaign Monitor Email': CampaignMonitorLogo,
    'Send in Blue Email': SendInBlueLogo,
    'Google AdSense': GoogleLogo,
    'Quantcast': QuantcastLogo,
    'SEMRush': SemrushLogo,
    "Google Sheets": GoogleSheetsLogo,
    "Amplitude": AmplitudeLogo,
}

export const ConnectorTypeSelect: React.FC<{
  selectConnectorType: (connectorType: string) => void;
  isNewCustomer: boolean;
}> = ({ selectConnectorType, isNewCustomer }) => {
  
  const { setIsOpen } = useTour()
  tourStepsManager([ 
    { 
      selector: '.tour-step-1', 
      heading: 'Choose a platform to connect.',
      description: 'The first step towards creating a data connector, is selecting the platform.  We\'re building new platforms all the time – if you\'re looking for something else, let us know!' 
    },
    {
      selector: '.tour-step-2', 
      heading: 'Filter for faster searching!',
      description: 'Search for a platform here to filter your results.' 
    }
  ])

  const meta = useContext(MetaContext);
  const [searched, setSearched] = useState("");
  const [selectedFalseConnector, setSelectedFalseConnector] = useState<string | null>(null);
  //@ts-expect-error
  let connectorsToRender: ConnectorConfig[] = meta.fullMeta
    ? //@ts-expect-error
      meta.fullMeta.connectors
    : [];
  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearched(e.target.value);
  };

  connectorsToRender = connectorsToRender.filter((connector) => !([
      "google_sheets",
      "basic_connector_group",
      "s3",
      "amplitude",
  ].includes(connector.name)))

  let falseConnectorsToRender = falseConnectorNameToIcon;

  if (searched) {
    connectorsToRender = connectorsToRender.filter((connector) => {
      return connector.label.toLowerCase().includes(searched.toLowerCase());
    });
    falseConnectorsToRender = Object.fromEntries(Object.entries(falseConnectorsToRender).filter(([key]) => key.toLowerCase().includes(searched.toLowerCase())));
  }
  const errorContext = useContext(ErrorContext);
  const onFalseConnectorClick = (falseConnector: string) => {
      APIPost("/connectors/register_interest", {data: {name: falseConnector}}, errorContext)
      setSelectedFalseConnector(falseConnector)
  }

  return (
    <>
      <div>
        <h1>Choose your platform</h1>
        <p>Which platform would you like to see in your dashboard?</p>
        <div className="ConnectorSearchBar tour-step-2" onClick={() => setIsOpen(false)}>
          <CustomizedInputBase
            sx={{ float: "none", margin: "auto" }}
            handleSearchInput={handleSearchInput}
          />
        </div>
        <div className="ConnectorTiles tour-step-1" onClick={() => setIsOpen(false)}>
          {connectorsToRender.map((connector) => {
            return (
                <ConnectorTile
                  onClick={() => selectConnectorType(connector.name)}
                  icon={getConnectorIcon(connector.name)}
                  label={connector.label}
                />
            );
          })}
          {
              isNewCustomer && Object.keys(falseConnectorsToRender).map((falseConnector) => {
                  return (
                      <ConnectorTile
                        onClick={() => onFalseConnectorClick(falseConnector)}
                        icon={falseConnectorNameToIcon[falseConnector]}
                        label={falseConnector}
                  />
                  )
              })
          }
        </div>
        <p>Can’t see the connector you need or need any help?</p>
        <a href="mailto:hello@thisissqueeze.com">Contact us!</a>
      </div>
      <FalseConnectorModal
        open={!!selectedFalseConnector}
        onClose={() => setSelectedFalseConnector(null)}
        connectorName={selectedFalseConnector}
      />
    </>
  );
};

const ConnectorTile: React.FC<{
    onClick: () => void,
    icon: string,
    label: string,
}> = ({
    onClick,
    icon,
    label ,
}) => {
    return (
        <div
          className="Tile"
          onClick={onClick}
        >
          <img
            src={icon}
            height="100px"
            alt="Social Media Icon"
          />
          <h3>{label}</h3>
        </div>
    )
}
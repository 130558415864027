import {fetchTeamsForUser} from "../util/API";
import {useQuery} from "@tanstack/react-query";

export const useTeamsForUserQuery = () => {
  const {data: teamsForUser, isLoading} = useQuery(['teamsForUser'], fetchTeamsForUser);
  return {
    teamsForUser,
    isLoading
  }
};

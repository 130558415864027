import { useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useRef, useState } from "react";
import GlobalStyles from "@mui/material/GlobalStyles";
import { Button } from "@mui/material";
import Lottie from "lottie-react";
import DashboardLottie from "../../assets/DashboardLottie.json";
import Loader from "Components/Loader";
import CreateConnectorStepper from "Components/ConnectorFlow/CreateConnectorStepper";
import { ErrorContext } from "Components/Providers/Error";
import { APIPost } from "util/API";
import { GenericField } from "./Fields/GenericField";
import { GenericConnectorFormData, GenericConnectorWidget } from "../../types";
import { ConnectorDetails } from "./StepPages/ConnectorDetails";
import { ConnectorTypeSelect } from "./StepPages/ConnectorTypeSelect";
import { CredentialsSelect } from "./StepPages/CredentialsSelect";
import { MetaContext } from "../Providers/MetaProvider";
import { useConnectorQuery } from "hooks/useConnectorQuery";
import * as FullStory from '@fullstory/browser';

import "./SelectConnector.css";

const inputGlobalStyles = (
  <GlobalStyles
    styles={{
      "& .MuiInputBase-root": { backgroundColor: "white", textAlign: "left" },
    }}
  />
);

const doesHaveCredentialStep = (config: null | any) => {
  if (config) {
    return config.fields.hasOwnProperty("credential_id");
  } else return null;
};

export const SelectConnector: React.FC<{ isNewCustomer: boolean }> = ({
  isNewCustomer,
}) => {
  // Context and queries
  const meta: any = useContext(MetaContext);
  const errorContext = useContext(ErrorContext);
  const navigate = useNavigate();
  const connectorQuery = useConnectorQuery();

  // State
  const [step, setStep] = useState(0);
  const connectorConfig = useRef<null | any>(null);
  const [isPendingSubmit, setIsPendingSubmit] = useState(false);

  let initialFormData: GenericConnectorFormData = {};
  const fields = connectorConfig.current ? connectorConfig.current.fields : {};
  const widgets: GenericConnectorWidget[] =
    connectorConfig.current && connectorConfig.current.extra_widgets
      ? connectorConfig.current.extra_widgets
      : [];
  const fieldNames = Object.keys(fields);
  fieldNames.forEach((fieldName) => (initialFormData[fieldName] = ""));
  const [formData, setFormData] = useState(initialFormData);

  const formDataComplete = fieldNames.every((value) => !!formData[value])
  // Functions
  const incrementStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const decrementStep = () => {
    setStep((prevStep) => prevStep - 1);
    if (!doesHaveCredentialStep(connectorConfig.current)) {
      setStep((prevStep) => prevStep - 1);
    }
  };

  const onBackClick = () => {
    if (doesHaveCredentialStep(connectorConfig.current)) {
      const setField = setFieldGenerator("credential_id");
      setField("");
    }
    decrementStep();
  };

  const selectConnectorType = (connectorType: string) => {
    connectorConfig.current = meta.fullMeta.connectors.find(
      //@ts-expect-error
      (connector) => connector.name === connectorType
    );
    setFormData((prevState) => {
      const newState = {...prevState}
      newState['name'] = connectorType
      return newState
    })
    incrementStep();
    if (!doesHaveCredentialStep(connectorConfig.current)) {
      incrementStep();
    }
  };

  function setFieldGenerator(fieldName: string) {
    const _setField = (value: string | number) => {
      let formDataCopy = Object.assign({}, formData);
      formDataCopy[fieldName] = value;
      setFormData(formDataCopy);
    };
    return _setField;
  }

  const fieldsToRender = Object.keys(fields).map((fieldName) => {
    if (fieldName === "credential_id") {
      return null;
    }

    return (
      <GenericField
        fieldName={fieldName}
        field={fields[fieldName]}
        setField={setFieldGenerator(fieldName)}
        formData={formData}
        widgets={widgets}
      />
    );
  });

  // Handle Submit
  const userInfo = meta?.fullMeta?.user_info;

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const submitUrl = connectorConfig.current.submit_url;
    const superSqueezeWelcomeUrl = !process.env.NODE_ENV || process.env.NODE_ENV === "development"
        ? "http://localhost:3001/analytics/welcome"
        : "https://app.thisissqueeze.com/analytics/welcome";
    setIsPendingSubmit(true);

    FullStory.event('Connector Created', {
        uid_str: meta.fullMeta.user_id,
        connector_name: formData.name,
        connector_type: connectorConfig.current.name,
    });

    APIPost(submitUrl, formData, errorContext)
      .then((response) => response.json())
      .then((resp) => {
        if (isNewCustomer && !userInfo?.is_superset_user) {
          window.location.href = superSqueezeWelcomeUrl
        } else {
          navigate("/home");
        }
      });
  };

  // Effects
  useEffect(() => {
    if (connectorQuery.isError) {
      // @ts-ignore
      errorContext.addError();
    }
  }, [connectorQuery.isError]);

  useEffect(() => {
    if (step === 1) {
      setFormData((prevState): GenericConnectorFormData => {
        if (prevState.hasOwnProperty('credential_id')) {
          return {credential_id: prevState['credential_id'], name: prevState.name}
        }
        return {name: prevState.name}
      })
    }
  }, [step])

  // Loading State
  if (connectorQuery.isLoading || isPendingSubmit) {
    return (
      <div style={{ height: "100vh" }}>
        <Loader />
      </div>
    );
  }

  // Reached Limit State
  if (connectorQuery.data && connectorQuery.data.length >= meta.maxConnectors) {
    return (
      <div className="LimitConnectorPage">
        <h2>Sorry, you've reached your connector limit</h2>
        <p>If you'd like to add more dashboards to your account</p>
        <a href="mailto:hello@thisissqueeze.com">
          <Button variant="contained">Contact us</Button>
        </a>
        <Lottie
          style={{ height: 300 }}
          animationData={DashboardLottie}
          loop={true}
        />
      </div>
    );
  }

  // Page steps
  let currentStepComponent;

  if (step === 0) {
    currentStepComponent = (
      <ConnectorTypeSelect selectConnectorType={selectConnectorType} isNewCustomer={isNewCustomer}/>
    );
  } else if (step === 1) {
    const credentialField = fields["credential_id"];
    currentStepComponent = credentialField && (
      <CredentialsSelect
        fieldName={"credential_id"}
        field={credentialField}
        setField={setFieldGenerator("credential_id")}
        formData={formData}
        widgets={widgets}
        incrementStep={() => setStep(2)}
        decrementStep={decrementStep}
      />
    );
  } else if (step === 2) {
    currentStepComponent = (
      <ConnectorDetails
        tourSteps={connectorConfig.current?.tour_info?.connector_details_page || []}  
        connectorTypeLabel={connectorConfig.current.label}
        fieldsToRender={fieldsToRender}
        onSubmit={handleSubmit}
        onBackClick={onBackClick}
        formDataComplete={formDataComplete}
      />
    );
  }


  return (
    <div className="CreateConnectorPage">
      {inputGlobalStyles}
      <>
        <CreateConnectorStepper step={step} />
        {currentStepComponent}
      </>
    </div>
  );
};

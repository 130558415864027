import React, { useContext } from "react";
import { Link } from "react-router-dom";

import "./NavBar.css";
import TeamSelect from "./TeamSelect";

import SqueezeLogo from "../../assets/SqueezeLogo.svg";
import BABILogo from "../../assets/BABI.svg";
import { MetaContext } from "../Providers/MetaProvider";

const superSqueezeUrl =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? "http://localhost:3001/analytics"
    : window.location.origin + "/analytics";

let logo = SqueezeLogo;
  if (
    window.location.origin.includes("beabear") ||
    window.location.origin.includes("babi")
  ) {
    logo = BABILogo;
  }

export const NavBar: React.FC = () => {
  const metaContext = useContext(MetaContext);

  // @ts-ignore
  const userInfo = metaContext?.fullMeta?.user_info;

  const dashboardUrl = userInfo?.is_superset_user
    ? "https://" + userInfo.legacy_reporting_url + "/dashboard/list/"
    : superSqueezeUrl + "/dashboards";
  const chartUrl = userInfo?.is_superset_user
    ? "https://" + userInfo.legacy_reporting_url + "/chart/list/"
    : superSqueezeUrl + "/charts";
  const themeUrl = userInfo?.is_superset_user
    ? "https://" + userInfo.legacy_reporting_url + "/chart/list/"
    : superSqueezeUrl + "/themes";

  return (
    <div className="navBarContainer">
      <div className="logoContainer">
        <Link to={"/home"}>
          <img src={logo} style={{ height: 40 }} />
        </Link>
      </div>
      <div className="linksContainer">
        <div className="redirectLinks">
          <a href={dashboardUrl}>Dashboards</a>
          <a href={chartUrl}>Charts</a>
          <a href={themeUrl}>Themes</a>
          <Link to="/home">Admin</Link>
        </div>
        <div className="userLinks">{/* ADD USER MODAL */}</div>
      </div>
    </div>
  );
};

import Dialog from "@mui/material/Dialog";
import React from "react";
import CogsImage from "../../assets/Cogs.svg";

import styles from "./FalseConnectorModal.module.css"


export const FalseConnectorModal: React.FC<{
  open: boolean,
  onClose: () => void
  connectorName: string | null
}> = ({
  open,
  onClose,
  connectorName
}) => {
    return (
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        onClose={onClose}
        transitionDuration={0}
      >
        <div className={styles.container}>
          <img className={styles.image} src={CogsImage}/>
          <div className={styles.textBox}>
            <h1>Sorry, we're still building {connectorName}.</h1>
            <p>We’ve registered your interest and will send you an email as soon as it’s released.</p>
          </div>
          <button
            className={styles.button}
            onClick={onClose}
          >
            Connect a different platform
          </button>
        </div>
      </Dialog>
    )
}
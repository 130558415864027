import {removeInvite as removeInviteAPI} from "../util/API";
import {useMutation, useQueryClient} from "@tanstack/react-query";


export const useRemoveInviteMutation = () => {
  const queryClient = useQueryClient();

  const {isLoading, mutate: removeInvite} = useMutation(
      removeInviteAPI,
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["pendingInvites"]);
        }
      }
  );
  return {removeInvite, isLoading}
};

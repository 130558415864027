import { createInvite as createInviteOnAPI } from "../util/API";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";

export const useCreateInviteMutation = () => {
  const queryClient = useQueryClient();
  const {isLoading, mutate: createInvite} = useMutation(
    createInviteOnAPI,
    {
        onSuccess: () => {
          queryClient.invalidateQueries(["pendingInvites"]);
        }
    }
  )
  return {createInvite, isLoading}
};

import { usePendingInvitesQuery } from "../../hooks/usePendingInvitesQuery";
import BasicTable from "../Table";
import { useRemoveInviteMutation } from "../../hooks/useRemoveInviteMutation";
import { MetaContext } from "../Providers/MetaProvider";
import { useContext } from "react";

import DeleteIcon from "../../assets/DeleteIcon.png";

export const PendingInvites = () => {
  const { data: pendingInvites, isLoading } = usePendingInvitesQuery();
  const { removeInvite } = useRemoveInviteMutation();
  const metaContext = useContext(MetaContext);
  // @ts-expect-error
  const myUserId = metaContext?.fullMeta?.user_info?.user_id;

  const renderInviteEmail = (invite: any) => invite.email;
  const renderInviteCreated = (invite: any) => invite.created_timestamp;
  const renderRemoveInvite = (invite: any) => {
    return (
      <button
        className="tableButton"
        onClick={() => removeInvite(invite.email)}
      >
        <img src={DeleteIcon} alt="delete button" style={{ height: 20 }} />
      </button>
    );
  };

  return (

    <BasicTable
      searchKey={(invite: any) => invite.email}
      rows={ pendingInvites || []}
      title="Pending Invites"
      headlines={[
        ["Email Address", "email"],
        ["Created", "created_timestamp"],
        ["Cancel Invite"]
      ]}
      search={false}
      isLoading={isLoading}
      renderFunctions={[renderInviteEmail, renderInviteCreated, renderRemoveInvite]}
      disablePagination={true}
    />
  );
};

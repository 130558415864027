import { CTAButton, PageLayout } from "Components/PageLayout/PageLayout";
import { AddTeamMember } from "../../Components/Team/AddTeamMember";

const AddTeamMemberPage = () => {
  const BackButton: CTAButton = {
    label: "Back",
    link: "/team",
  };

  return (
    <PageLayout title="Invite Team">
      <AddTeamMember />
    </PageLayout>
  );
};

export default AddTeamMemberPage;

import cookie from 'react-cookies';


export const API_HOST = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? 'http://localhost:3000' : window.location.origin + "/api"

// We have to work around here, because we're opening a window, so the host in this case is relative
// to the machine, as opposed to the container the frontend is in
export const OAUTH_HOST = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? 'http://localhost:8000' : API_HOST


export function APIGet (url, errorContext) {
  const response = fetch(API_HOST + url, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  }).then((resp) => {
    if (!resp.ok) {
      return resp.json().then(
          (respJson) => Promise.reject(respJson?.errors?.[0]?.description)
      )
    }
    return resp.json();
  }).catch(error => {
    if (typeof error === "string") {
      errorContext.addError(error);
    } else {
      errorContext.addError();
    }
  })
  return response;
}


export function getMetaFromAPI(errorContext) {
  return APIGet('/connectors/meta', errorContext).then(
      jsonResponse => {
        return jsonResponse.data;
      }
    );
}

export function getTourFromAPI(errorContext) {
  return APIGet('/crm/get_tour', errorContext).then(
      jsonResponse => {
        return jsonResponse.data;
      }
    );
}

export function APIPost(url, body, errorContext) {
  let paramsForFetch = {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': cookie.load('csrftoken')
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  }
  if (body) {
    paramsForFetch.body = JSON.stringify(body)
  }
  const response = fetch(API_HOST + url, paramsForFetch).then((resp) => {
    if (resp.status <= 599 && resp.status >= 500) {
      errorContext.addError();
    }
    return resp;
  }).then((resp) => {
    return resp;
    }
  )
  return response;
}


export function loginToAPI(username, password, errorContext) {
  const body = {username: username, password: password};
  return APIPost('/connectors/login', body, errorContext).then((response) => {
    return response.ok
  })
}

export function logoutFromAPI(errorContext) {
  return APIPost('/connectors/logout', null, errorContext)
    .then((response) => response.json())
    .then((response) => response.data.logout_url);
}

export function inviteUserAPI(errorContext) {
    const body = {
            data: {
                emails: ['domisamazing27@gmail.com']
            }
        };
    return APIPost('/crm/create_invites', body, errorContext).then((response) => {
        return response.ok
    })

}

export function deleteConnectorFromAPI(id, errorContext) {
  return APIPost(`/connectors/${id}/delete`, null, errorContext).then((response) => {
    return response.ok
  })
}

// BEGIN REACT QUERY

// Same as APIGet but without using errorContext
export const fetchFromAPI = async (url) => {
  const response = await fetch(API_HOST + url, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  });
  return await response.json();
}

// Same as APIPost but without using errorContext
export const postToApi = async (url, body) => {
  let paramsForFetch = {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': cookie.load('csrftoken')
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  }
  if (body) {
    paramsForFetch.body = JSON.stringify(body)
  }
  return await fetch(API_HOST + url, paramsForFetch)
}

export const fetchConnectors = async () => {
  const connectorResponse = await fetchFromAPI('/connectors/list');
  return connectorResponse.data;
}

export const fetchCredentials = async() => {
  const credentialsResponse = await fetchFromAPI('/credentials/')
  return credentialsResponse.data;
}

export const fetchDashboards = async() => {
  const dashboardsResponse = await fetchFromAPI('/reporting/dashboards')
  return dashboardsResponse.data;
}

export const fetchWorkflows = async (id) => {
  const workflowResponse = await fetchFromAPI(`/connectors/${id}/recent_workflows`);
  return workflowResponse.data;
}

export const createInvite = async (emailAddresses) => {
  return await postToApi('/crm/create_invites', {'data': {'emails': emailAddresses}})
}

export const removeUserFromTeam = async (username) => {
  return await postToApi('/crm/remove_user_from_team', {'data': {'username': username}})
}

export const removeInvite = async (email) => {
  return await postToApi('/crm/remove_invite', {'data': {'email': email}})
}

export const fetchUsersInTeam = async () => {
  const usersInTeamResponse = await fetchFromAPI('/crm/all_users_in_team')
  return usersInTeamResponse.data
}

export const fetchTeamsForUser = async () => {
  const teamsForUser = await fetchFromAPI('/crm/all_teams')
  return teamsForUser.data
}

export const fetchPendingInvites = async () => {
  const pendingInvitesResponse = await fetchFromAPI('/crm/pending_invites')
  return pendingInvitesResponse.data
}

export const changeTeam = async (teamId) => {
  return await postToApi('/crm/change_team', {'data': {'team_id': teamId}})
}

// END REACT QUERY

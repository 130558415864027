import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    //@ts-ignore
    type: "light",
    primary: {
      main: "#FB046E",
      dark: "#9E0044",
    },
    secondary: {
      main: "#6D6A75",
      light: "#BFBDC1",
      dark: "#37323E",
    },
    white: {
      main: "#ffffff",
    },
  },
  //@ts-ignore
  text: {
    primary: "#000000",
    secondary: "#ffffff",
  },
  // typography: {
  //   fontFamily: "Raleway, sans-serif",
  // },
  drawerPaper: {
    width: "inherit",
    paddingTop: 0,
  },
});

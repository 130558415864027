import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid } from "@mui/material";

export default function Modal(props) {
  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={props.open}
        onClose={props.handleClose}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          p={2}
        >
          <Grid item xs={6}>
            <DialogTitle>{props.title}</DialogTitle>
          </Grid>
          <Grid item xs={6}>
            <DialogActions>
              <Button variant="contained" onClick={props.handleClose}>
                Close
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
        <DialogContent>{props.children}</DialogContent>
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          pb={1}
          px={2}
        >
          {props.deleteFunction ? (
            <Grid item>
              <DialogActions>{props.deleteFunction}</DialogActions>
            </Grid>
          ) : null}
        </Grid>
      </Dialog>
    </React.Fragment>
  );
}

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import * as FullStory from '@fullstory/browser';

import "./index.css";
import {ErrorBoundary} from "./Components/ErrorBoundary/ErrorBoundary";

Sentry.init({
  dsn: "https://eff8749a8ded4d5b944405e0e429f9a4@o993257.ingest.sentry.io/4504413380542464",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  enabled: process.env.NODE_ENV !== 'development',
});

FullStory.init({
    orgId: 'o-1KA7F9-na1',
    devMode: process.env.NODE_ENV == 'development',
});


const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <QueryClientProvider client={queryClient}>
    <ErrorBoundary>
        <Router basename="/admin">
          <App />
        </Router>
        <ReactQueryDevtools initialIsOpen={false} />
    </ErrorBoundary>
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

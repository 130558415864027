import { Routes, Route, Navigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { RequireAuth } from "./Components/Providers/Auth";
import { MetaProvider } from "./Components/Providers/MetaProvider";
import ErrorSnackbar, { ErrorProvider } from "./Components/Providers/Error";
import Home from "./pages/Home/Home";
import Dashboards from "pages/Dashboards/Dashboards";
import AddDashboard from "pages/AddDashboard/AddDashboard";
import Accounts from "./pages/Accounts/Accounts";
import AddConnector from "pages/AddConnector/AddConnector";
import { theme } from "util/theme";
import "./App.css";
import Team from "./pages/Team/Team";
import AddTeamMember from "./pages/AddTeamMember/AddTeamMember";
import { TourProvider } from '@reactour/tour'
import {Welcome} from "./pages/Welcome/Welcome";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <ErrorProvider>
          <MetaProvider>
            <RequireAuth>
              <Routes>
                <Route path="/home" element={<Home />} />
                <Route path="/accounts" element={<Accounts />} />
                <Route path="/dashboards" element={<Dashboards />} />
                <Route path="/team" element={<Team />} />
                <Route path="/add_dashboard" element={<AddDashboard />} />
                <Route path="/add_connector" element={
                      <AddConnector/>
                    } />
                <Route path="/add_team_member" element={<AddTeamMember />} />
                <Route path="/welcome" element={<Welcome />} />
                <Route path="/" element={<Navigate to="/welcome" replace />} />
                <Route
                  path="/new_customer_flow"
                  element={
                      <AddConnector
                        showClippedDrawer={false}
                        requireConnectors={false}
                        isNewCustomer={true}
                      />
                  }
                />
              </Routes>
            </RequireAuth>
          </MetaProvider>
          <ErrorSnackbar />
        </ErrorProvider>
      </div>
    </ThemeProvider>
  );
};

export default App;

import React from "react";

import SqueezeLogoWhite from "../../assets/SqueezeLogoWhite.svg";
import SampleDashboard from "../../assets/SampleDashboard.svg";

import {useNavigate} from "react-router-dom";
import {useConnectorQuery} from "../../hooks/useConnectorQuery";

import "./Welcome.css";


export const Welcome = () => {
    const navigate = useNavigate();

    const { data: connectors, isLoading: isLoadingConnectors } = useConnectorQuery();

    if (isLoadingConnectors) {
        return null;
    }

    if (connectors && connectors.length > 0) {
        navigate("/home");
    }

    return (
        <div className="welcomePageContainer">
            <div className="welcomeModal">
                <div className="welcomeModalTop">
                    <img src={SqueezeLogoWhite} />
                    <img src={SampleDashboard} />
                </div>
                <div className="welcomeModalTextBox">
                    <h1>Hey there 👋</h1>
                    <p>
                        Welcome to Squeeze. We just need a few bits of information to get you started. First up
                        – creating a data connector.
                    </p>
                    <p>
                        Each account we sync is managed through a data connector.
                        We’re going to walk you through creating your first one now!
                    </p>
                </div>
                <button
                    className="welcomeModalButton"
                    onClick={() => navigate('/new_customer_flow')}
                >
                    Create my first connector
                </button>
            </div>
        </div>
    )
}
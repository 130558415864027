import { useUsersInTeamQuery } from "../../hooks/useUsersInTeamQuery";
import BasicTable from "../Table";
import { useRemoveUserFromTeamMutation } from "../../hooks/useRemoveUserFromTeamMutation";
import { MetaContext } from "../Providers/MetaProvider";
import { useContext } from "react";

import DeleteIcon from "../../assets/DeleteIcon.png";

export const Team = () => {
  const { data: usersInTeam, isLoading } = useUsersInTeamQuery();
  const { removeUserFromTeam } = useRemoveUserFromTeamMutation();
  const metaContext = useContext(MetaContext);
  // @ts-expect-error
  const myUserId = metaContext?.fullMeta?.user_info?.user_id;

  const renderUsername = (user: any) => user.username;
  const renderEmailAddress = (user: any) => {
    let toReturn = user.email;
    if (myUserId === user.id) {
      toReturn += " (you)";
    }
    return toReturn;
  };
  const renderRemoveUser = (user: any) => {
    if (user.id === myUserId) {
      return null;
    }
    return (
      <button
        className="tableButton"
        onClick={() => removeUserFromTeam(user.username)}
      >
        <img src={DeleteIcon} alt="delete button" style={{ height: 20 }} />
      </button>
    );
  };

  return (
    <BasicTable
      searchKey={(user: any) => user.username + user.email}
      rows={usersInTeam || []}
      title="Team Members"
      headlines={[
        ["Email Address", "email"],
        ["Username", "username"],
        ["Remove", "remove"],
      ]}
      search={true}
      isLoading={isLoading}
      renderFunctions={[renderEmailAddress, renderUsername, renderRemoveUser]}
      disablePagination={true}
    />
  );
};

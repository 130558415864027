import { Connectors } from "Components/Connectors";
import { CTAButton, PageLayout } from "Components/PageLayout/PageLayout";

const Home = () => {
  const CTAButton: CTAButton = {
    label: "Create connector",
    link: "/add_connector",
  };

  return (
    <PageLayout title="Connector List" CTAButton={CTAButton}>
      <Connectors />
    </PageLayout>
  );
};

export default Home;

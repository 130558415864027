import { SelectConnector } from "Components/ConnectorFlow/SelectConnector";
import { PageLayout } from "Components/PageLayout/PageLayout";

import "./AddConnector.css";

const AddConnector: React.FC<{
  showClippedDrawer?: boolean;
  requireConnectors?: boolean;
  isNewCustomer?: boolean;
}> = ({
  showClippedDrawer = true,
  requireConnectors = true,
  isNewCustomer = false,
}) => {
  if (!showClippedDrawer) {
    return <SelectConnector isNewCustomer={isNewCustomer} />;
  }

  return (
    <PageLayout requireConnectors={requireConnectors}>
      <SelectConnector isNewCustomer={isNewCustomer} />
    </PageLayout>
  );
};

export default AddConnector;

import React, { useState } from "react";
import { useCreateInviteMutation } from "../../hooks/useCreateInviteMutation";
import { useNavigate } from "react-router-dom";

import DeleteButton from "../../assets/DeleteIconPink.png";
import PlusSign from "../../assets/PlusSign.png";

import "./AddTeam.css";

const EmailInput: React.FC<{
  emailAddress: string;
  changeEmailAddress: (emailAddress: string) => void;
  deleteEmailAddress: () => void;
}> = ({ emailAddress, changeEmailAddress, deleteEmailAddress }) => {
  return (
    <div className="addTeamInputContainer">
      <input
        className="addTeamInput"
        value={emailAddress}
        onChange={(event) => changeEmailAddress(event.target.value)}
      />
      <button className="deleteTeamMemberButton" onClick={deleteEmailAddress}>
        <img src={DeleteButton} alt="delete icon" style={{ height: 20 }} />
      </button>
    </div>
  );
};

export const AddTeamMember = () => {
  const [emailAddresses, setEmailAddresses] = useState<string[]>([""]);
  const addNewEmailAddress = () => {
    setEmailAddresses((prevState) => [...prevState, ""]);
  };
  const changeEmailAddressGenerator = (idx: number) => {
    const changeEmailAddress = (emailAddress: string) => {
      setEmailAddresses((prevState) => {
        const copiedEmailAddresses = [...prevState];
        copiedEmailAddresses[idx] = emailAddress;
        return copiedEmailAddresses;
      });
    };
    return changeEmailAddress;
  };

  const deleteEmailAddressGenerator = (idx: number) => {
    const deleteEmailAddress = () => {
      setEmailAddresses((prevState) => {
        const copiedEmailAddresses = [...prevState];
        copiedEmailAddresses.splice(idx, 1);
        return copiedEmailAddresses;
      });
    };
    return deleteEmailAddress;
  };

  const { createInvite } = useCreateInviteMutation();
  const navigate = useNavigate();

  const createInvitesForEmailAddresses = () => {
    createInvite(emailAddresses.filter((emailAddress) => emailAddress !== ""));
    navigate("/team");
  };

  return (
    <div className="addEmailContainer">
      {emailAddresses.map((emailAddress, idx) => {
        return (
          <EmailInput
            emailAddress={emailAddress}
            changeEmailAddress={changeEmailAddressGenerator(idx)}
            deleteEmailAddress={deleteEmailAddressGenerator(idx)}
          />
        );
      })}
      <button className="addEmailButton" onClick={addNewEmailAddress}>
        <img src={PlusSign} alt="plus sign" style={{ height: 15 }} />
        Add Email
      </button>
      <button
        className="inviteEmailsButton"
        onClick={createInvitesForEmailAddresses}
      >
        Invite
      </button>
    </div>
  );
};

import { useContext, useState, useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useTeamsForUserQuery } from "../../hooks/useTeamsForUserQuery";
import { useChangeTeamMutation } from "../../hooks/useChangeTeamMutation";
import { MetaContext } from "../Providers/MetaProvider";

export default function TeamSelect() {
  const { teamsForUser } = useTeamsForUserQuery();
  const { changeTeam } = useChangeTeamMutation();
  const metaContext = useContext(MetaContext);

  const [teamId, setTeamId] = useState(null);

  const handleChange = (event: SelectChangeEvent) => {
    const newTeamId = event.target.value;
    changeTeam(newTeamId);
  };

  useEffect(() => {
    if (metaContext && !teamId) {
      // @ts-expect-error
      const teamIdFromMeta = metaContext?.fullMeta?.user_info?.team_id;
      setTeamId(teamIdFromMeta);
    }
  }, [metaContext]);

  return (
    <div style={{ minWidth: 120 }}>
      <FormControl fullWidth size="small">
        <InputLabel
          shrink
          id="team-change-input-label"
          sx={{ backgroundColor: "#FFF", fontFamily: "Raleway" }}
        >
          Team:
        </InputLabel>
        <Select
          labelId="team-change-input-label"
          id="team-change-input-select"
          value={teamId || ""}
          onChange={handleChange}
          sx={{ fontFamily: "Raleway", fontSize: 14, fontWeight: 500 }}
          autoWidth
        >
          {teamsForUser &&
            teamsForUser.map((team: any) => {
              return <MenuItem value={team.id}>{team.label}</MenuItem>;
            })}
        </Select>
      </FormControl>
    </div>
  );
}

import React, { useContext, useEffect, useState } from "react";
import { Grid, TextField, MenuItem } from "@mui/material";
import { ErrorContext } from "Components/Providers/Error";
import { APIGet } from "util/API";
import Loader from "Components/Loader";
import { GenericWidget } from "./Widget";
import {GenericConnectorField, GenericConnectorFormData, GenericConnectorWidget} from "../../../types";
import { tourStepsManager } from '../../ReactTour'
import { useTour } from '@reactour/tour'


export const CredentialField: React.FC<{ fieldName: string; setField: (value: string | number) => void; formData: GenericConnectorFormData; field: GenericConnectorField; widgets: GenericConnectorWidget[]; }> = ({ fieldName, setField, formData, field, widgets }) => {
  const errorContext = useContext(ErrorContext);
  const [APIChoices, setAPIChoices] = useState<null | { id: string | number; label: string; }[]>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isPolling, setIsPolling] = useState(false);

  const prettyName = 'Account';

  const pollForCredentials = async () => {
    const apiResponse = await APIGet(field.url, errorContext);
    const latestCredentials: { id: string | number; label: string; }[] = apiResponse.data;
    const latestCredentialsIds = latestCredentials.map((cred) => cred.id);
    const apiChoiceIds = APIChoices?.map((cred) => cred.id) || [];
    const newCredentialsIds = latestCredentialsIds.filter((cred) => !apiChoiceIds.includes(cred));
    const newCredentialsId = newCredentialsIds && newCredentialsIds[0];
    if (newCredentialsId) {
      setIsPolling(false);
      setField(newCredentialsId);
    }
  };


  useEffect(() => {
    if (!APIChoices) {
      let url = field.url;
      APIGet(url, errorContext).then((resp) => {
        setAPIChoices(resp.data);
        setIsLoading(false);
      });

    }
  }, [APIChoices]);

  useEffect(() => {
    if (isPolling) {
      let interval = setInterval(pollForCredentials, 2000);
      return () => clearInterval(interval);
    }
  }, [isPolling]);


  if (isLoading) {
    return <Loader />;
  }

  var tourSteps = []

  if (!APIChoices || APIChoices.length === 0) {
//       tourSteps =
//         [{
//           selector: '.auth-details-step-one',
//           heading: "Sign in with your account to continue",
//           description: "Click below to sign in... "
//         }]
//         tourStepsManager(tourSteps)
    return (
      <div className="CredentialComponentWidgetOnly">
        {widgets.map((widget) => {
          return <GenericWidget
            extraOnClick={() => setIsPolling(true)}
            widget={widget}
            classNameString={"auth-details-step-one"}
          />;
        })}
      </div>

    );
//   }else{
//       tourSteps = [{
//           selector: '.auth-details-step-one',
//           heading: "Select account",
//           description: "blah... "
//         },
//         {
//           selector: '.auth-details-step-two',
//           heading: "Sign in with your account to continue",
//           description: "Click below to sign in... "
//         }]
    }
//
//   tourStepsManager(tourSteps)

  return (
    <div className="CredentialComponent">
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <p>Use your existing account:</p>
          <TextField
            required
            select
            value={formData[fieldName]}
            id={fieldName}
            fullWidth
            label={field.label || prettyName}
            placeholder={(field.placeholder || prettyName) as string}
            onChange={(e) => setField(e.target.value)}
            inputProps={{
                className: `auth-details-step-one`,
              }}
          >
            {APIChoices &&
              APIChoices.map((choice) => {
                return (
                  <MenuItem key={choice.id} value={choice.id}>
                    {choice.label}
                  </MenuItem>
                );
              })}
          </TextField>
        </Grid>
        <Grid item xs={1} alignSelf={"center"}>
        </Grid>
      </Grid>
      <div className="credentialSeparator">
        <div />
        <p>OR</p>
        <div />
      </div>
      <div className="widgetContainer">
        {widgets.map((widget) => {
          return <GenericWidget
            extraOnClick={() => setIsPolling(true)}
            widget={widget}
            classNameString={"auth-details-step-two"}
          />;
        })}
      </div>

    </div>

  );
};

import { DashboardList } from "../../Components/Dashboards/DashboardList";
import { CTAButton, PageLayout } from "Components/PageLayout/PageLayout";

const Dashboards = () => {
  const CTAButton: CTAButton = {
    label: "Create dashboard",
    link: "/add_dashboard",
  };
  return (
    <PageLayout title="Dashboards" CTAButton={CTAButton}>
      <DashboardList />
    </PageLayout>
  );
};

export default Dashboards;

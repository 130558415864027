import * as Sentry from "@sentry/react";
import { Component, ErrorInfo, ReactNode } from "react";
import { CopyBlock, dracula } from "react-code-blocks";

import "./ErrorBoundary.css";

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null;
}

export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    error: null,
  };

  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    Sentry.captureException(error);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div className="errorPage">
          <p className="errorPageEmoji">🤕</p>
          <h1>Oops, something went wrong!</h1>
          <p>
            To help us fix it, please copy/paste the message below and{" "}
            <a href="mailto:hello@thisissqueeze.com">send it to us</a>. Thanks
            for helping us make the product better!
          </p>
          <CopyBlock
            text={this.state.error!.stack}
            theme={dracula}
            language="javascript"
            // showLineNumbers={props.showLineNumbers}
            wrapLines
          />
        </div>
      );
    }

    return this.props.children;
  }
}

import React from "react";
import {Button} from "@mui/material";
import {openCredentialWindow} from "util/Utils";
import {API_HOST} from "util/API";
import {GenericConnectorWidget} from "../../../types";
import FacebookLogoWhite from "../../../assets/FacebookLogoWhite.png";
import GoogleSignIn from "../../../assets/GoogleSignIn.png";
import TikTokLogo from "../../../assets/TikTokLogo.svg";
import PinterestLogo from "../../../assets/PinterestLogoWhite.png";
import LinkedInLogo from "../../../assets/LinkedIn_logo.png";
import TwitterLogo from "../../../assets/Twitter-logo.png";

import styles from "./Widget.module.css";


export const GenericWidget: React.FC<{
    widget: GenericConnectorWidget;
    extraOnClick: () => void;
    classNameString: string;
}> = ({ widget, extraOnClick, classNameString }) => {

  const onWidgetClick = () => {
    extraOnClick();
    openCredentialWindow(API_HOST + widget.url);
  }
  
  if (widget.type === "facebook-button") {
    return (
      <button className={styles.facebookButton} onClick={onWidgetClick}>
        <img src={FacebookLogoWhite} />
        <p>Continue with Facebook</p>
      </button>
    )
  }

  if (widget.type === "google-button") {
    return (
      <button 
        className={styles.googleButton} 
        onClick={onWidgetClick}
      >
        <img src={GoogleSignIn} />
      </button>
    )
  }

  if (widget.type === "tiktok-button") {
    return (
      <button className={styles.tiktokButton} onClick={onWidgetClick}>
        <img src={TikTokLogo} />
        <p>Continue with TikTok</p>
      </button>
    )
  }

  if (widget.type === "pinterest-button") {
    return (
      <button className={styles.pinterestButton} onClick={onWidgetClick}>
        <img src={PinterestLogo} />
        <p>Continue with Pinterest</p>
      </button>
    )
  }

  if (widget.type === "linkedin-button") {
    return (
      <button className={styles.linkedinButton} onClick={onWidgetClick}>
        <img src={LinkedInLogo} />
        <p>Continue with LinkedIn</p>
      </button>
    )
  }

  if (widget.type === "twitter-button") {
    return (
      <button className={styles.twitterButton} onClick={onWidgetClick}>
        <img src={TwitterLogo} />
        <p>Continue with Twitter</p>
      </button>
    )
  }

  if (widget.type === "button") {
    return (
      <Button
        // fullWidth
        color="primary"
        variant="contained"
        onClick={onWidgetClick}
        className={classNameString}
      >
        {" "}
        {widget.label}{" "}
      </Button>
    );
  }

  return null;
};

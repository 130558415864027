import {changeTeam as changeTeamAPI} from "../util/API";
import {useMutation, useQueryClient} from "@tanstack/react-query";


export const useChangeTeamMutation = () => {
  const queryClient = useQueryClient();

  const {isLoading, mutate: changeTeam} = useMutation(
      changeTeamAPI,
      {
        onSuccess: () => {
            window.location.reload();
        }
      }
  );
  return {changeTeam, isLoading}
};

import { CTAButton, PageLayout } from "Components/PageLayout/PageLayout";
import { Team } from "../../Components/Team/Team";
import { PendingInvites } from "../../Components/Team/PendingInvites";
import TeamSelect from "Components/NavBar/TeamSelect";

import "./Team.css";

const TeamPage = () => {
  const CTAButton: CTAButton = {
    label: "Add Team Member",
    link: "/add_team_member",
  };

  return (
    <PageLayout title="Team" CTAButton={CTAButton}>
      <>
        <div className="teamMembersPageChangeTeamBox">
          <h3 className="pageTitle teamMembersChange">Change team: </h3>
          <TeamSelect />
        </div>
        <h3 className="pageTitle">Team Members</h3>
        <Team />
        <h3 className="pageTitle">Pending Invites</h3>
        <PendingInvites />
      </>
    </PageLayout>
  );
};

export default TeamPage;

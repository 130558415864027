import {removeUserFromTeam as removeUserFromTeamAPI} from "../util/API";
import {useMutation, useQueryClient} from "@tanstack/react-query";


export const useRemoveUserFromTeamMutation = () => {
  const queryClient = useQueryClient();

  const {isLoading, mutate: removeUserFromTeam} = useMutation(
      removeUserFromTeamAPI,
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["usersInTeam"]);
        }
      }
  );
  return {removeUserFromTeam, isLoading}
};

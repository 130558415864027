import GoogleAnalyticsLogo from '../assets/google-analytics-logo.png';
import GoogleAnalytics4Logo from '../assets/google_analytics_4.svg';
import InstagramLogo from '../assets/Instagram_logo.png';
import DefaultImage from '../assets/DefaultImage.png';
import FacebookLogo from '../assets/FacebookLogo.png'
import GoogleAdsLogo from '../assets/GoogleAdsLogo.png';
import GoogleSheetsLogo from '../assets/GoogleSheetsLogo.png';
import LinkedinLogo from '../assets/LinkedIn_logo.png';
import TwitterLogo from '../assets/Twitter-logo.png';
import TikTokLogo from '../assets/TikTokLogo.png';
import ShopifyLogo from '../assets/ShopifyLogo.png';
import PinterestLogo from '../assets/Pinterest-logo.png';
import YoutubeLogo from '../assets/Youtube-logo.png';
import S3Logo from '../assets/S3-logo.png'
import KlaviyoLogo from '../assets/KlaviyoLogo.svg'
import OutbrainLogo from '../assets/OutbrainLogo.png'
import AmplitudeLogo from '../assets/AmplitudeLogo.png'
import TaboolaLogo from '../assets/TaboolaLogo.png';
import MailchimpLogo from '../assets/MailchimpLogo.svg';
import FacebookAdsLogo from "../assets/FacebookAdsIcon.svg";
import AwinLogo from "../assets/AwinLogo.svg";

const ConnectorIcons = {
    google_analytics: GoogleAnalyticsLogo,
    google_analytics_4: GoogleAnalytics4Logo,
    instagram_business: InstagramLogo,
    google_ads: GoogleAdsLogo,
    linkedin: LinkedinLogo,
    google_sheets: GoogleSheetsLogo,
    twitter_organic: TwitterLogo,
    tiktok_organic: TikTokLogo,
    tiktok_ads: TikTokLogo,
    taboola: TaboolaLogo,
    facebook_ads: FacebookAdsLogo,
    facebook_organic: FacebookLogo,
    shopify: ShopifyLogo,
    youtube: YoutubeLogo,
    klaviyo: KlaviyoLogo,
    s3: S3Logo,
    pinterest: PinterestLogo,
    outbrain_amplify: OutbrainLogo,
    amplitude: AmplitudeLogo,
    mailchimp: MailchimpLogo,
    awin: AwinLogo,
}

export function getConnectorIcon (connectorType) {
    return ConnectorIcons[connectorType] || DefaultImage;
}
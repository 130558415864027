import React from "react";
import { Credentials } from "Credentials/Credentials";
import { PageLayout } from "Components/PageLayout/PageLayout";

const Accounts = () => {
  return (
    <PageLayout title="Credentials">
      <Credentials />
    </PageLayout>
  );
};

export default Accounts;

import React from "react";
import Dialog from "@mui/material/Dialog";
import "./ConnectorIntroModal.css";
import SampleDashboard from "../../assets/SampleDashboard.svg";
import SqueezeLogoWhite from "../../assets/SqueezeLogoWhite.svg";


export const ConnectorIntroModal: React.FC = () => {

    const onButtonClick = () => {
        window.location.href = !process.env.NODE_ENV || process.env.NODE_ENV === "development"
          ? "http://localhost:3001/analytics/welcome"
          : "https://app.thisissqueeze.com/analytics/welcome";
    }

    return (
      <Dialog
        open={true}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <div className="container">
            <div className="banner">
                <img src={SqueezeLogoWhite} />
                <img src={SampleDashboard} />
            </div>
            <div className="description">
                <h1>You created your first connector! 🎉</h1>
                <p>Awesome, it’s time to ‘Explore’ your data in Squeeze! Get ready to create beautiful visualisations, save them as Charts and add to Dashboards.</p>
                <p>Just a heads up, the first data sync can take a short while, depending on the amount of historical data you have. If you don't see your data right away, don't fret! We're working hard to bring it to you as soon as possible.</p>
                <button
                    className="exploreButton"
                    onClick={onButtonClick}
                >
                    Explore my data
                </button>
            </div>
        </div>
      </Dialog>
    )
}
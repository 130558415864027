import React from "react";
import { TextField } from "@mui/material";
import { prettifySnakeCase } from "util/Utils";
import {GenericConnectorField} from "../../../types";
import { useState, useEffect } from "react";
import { ControlCameraTwoTone } from "@mui/icons-material";

export const StringField: React.FC<{ formData: any, fieldName: string; setField: (value: string) => void; field: GenericConnectorField;}> = ({ formData, fieldName, setField, field }) => {
  const prettyName = prettifySnakeCase(fieldName);

  const handleUpdateTextFieldValue = (newValue: string) => {
    setField(newValue)
  };

  return (
    <TextField
      required
      value={formData?.[fieldName] || ""}
      id={fieldName}
      fullWidth
      label={field.label || prettyName}
      placeholder={(field.placeholder || prettyName) as string}
      onChange={(e) => handleUpdateTextFieldValue(e.target.value)}
      inputProps={{
        className: `data-hj-allow ${field.class_name || ''}`, 
        maxLength: fieldName === 'name' ? 200 : null
      }}
      />
  );
};
